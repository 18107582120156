import '../App.css';
import marriage from '../images/marriage.png';
import english from '../images/english.png';
import elm from '../images/elm.png';
import todolist from '../images/todolist.png';
import panel from '../images/panel.PNG'
import { useContext } from 'react';
import { Context } from '../Context';
import Texts from '../Texts';

function Portfolio() {

  const {lang} = useContext(Context);
  const texts = Texts[lang]

  return (
    <div className='portfolio animation'>
      <div>
        <img src={marriage} alt=''></img>
        <p>{texts.project1}</p>
        <a target='_blank' href='https://marriage.waseela.online'>{texts.view}</a>
      </div>
      <div>
        <img src={panel} alt=''></img>
        <p>{texts.project2}</p>
        <a target='_blank' href='https://res.waseela.online'>{texts.view}</a>
      </div>
      <div>
        <img src={elm} alt=''></img>
        <p>{texts.project3}</p>
        <a target='_blank' href='https://waseela-app.github.io/appfixed/#/home'>{texts.view}</a>
      </div>
      <div>
        <img src={english} alt=''></img>
        <p>{texts.project4}</p>
        <a target='_blank' href='https://waseela-english-level-test.glitch.me'>{texts.view}</a>
      </div>
      <div>
        <img src={todolist} alt=''></img>
        <p>{texts.project5}</p>
        <a target='_blank' href='https://waseelaproject.github.io/to-do-list/'>{texts.view}</a>
      </div>
    </div>
  );
}

export default Portfolio;