const texts = {
    en: {
        langbtn: "Spanish",
        home: "Home",
        about: "About",
        skills: "Skills",
        portfolio: "Portfolio",
        hi: "Hi, I'm Waseela",
        webdev: "Web Developer",
        downloadcv: "Download CV",
        summery: "Summery:",
        summery1: "Waseela is a Software Engineer specialized in web development, He has worked in different projects for many clients to design and develop services, applications, control panels and interactive websites.",
        summery2: "He has been working in various enterprises for more than three years making him an expert fullstack developer.",
        education: "Education:",
        education1: "B.Sc.(Hons) in Mechatronics Engineering, Future University, Khartoum, Sudan, 07/2016 - 10/2021.",
        courses: "Courses:",
        courses1: "Java programming (solve problems with software), from Duke university offered through Coursera.",
        courses2: "Object Oriented Programming in java by university of California San Diego offered through Coursera.",
        courses3: "Getting started with Spring Boot by Amigoscode.",
        experience: "Professional Experience:",
        experience1: "More than a three years of professional experience in software engineering, two years as a front-end developer and one year as a back-end developer, which helped me gain the skills of:",
        experience2: "Creation of rest apis and responsive web designs.",
        experience3: "Determine requirements and the best technologies for the projects depending on its idea.",
        experience4: "Analyse websites performance, problems, bugs and diagnostics.",
        experience5: "Interfacing with data access layers for sql and nosql systems.",
        experience6: "Build modular and reusable components.",
        experience7: "Testing and writing documentation at every stage of the software development.",
        experience8: "Working with agile metholodogy in projects with clients from different countries.",
        experience9: "Functionality of third party APIs such as payment gateways and SMS OTP verification.",
        experience10: "Dynamics of web and public/private cloud services.",
        experience11: "Partner with designers to achieve the best possible outcome.",
        experience12: "Mentoring engineers in technologies, patterns, and best practices while leading by example in my area of expertise.",
        experience13: "Maintaining and improving existing codebase with team members via a coding platform such as github or jira.",
        experience14: "Complete tasks of higher priority first and organize them using platforms such as clickup and trello.",
        experience15: "Daily meetings with team members and clients via zoom or google meet.",
        qualifications: "Qualifications:",
        qualifications1: "The ability to speak in three languages which are: Arabic(native), English(fluent), Spanish(b2).",
        qualifications2: "Strong knowledge in data structures and algorithms.",
        qualifications3: "Ability to code with different programming languages.",
        qualifications4: "Strong knowledge in manipulating, storing and retrieving data, cookies, sessions, local storage and user tokens.",
        qualifications5: "Transfare any design into a fully functional application.",
        qualifications6: "High experience in hosting, domains and SSL certificates.",
        qualifications7: "Experience with designing, planning and implementing backend services/microservices and apis.",
        qualifications8: "Responsibility to design and develop software that embraces quality and reliability while delivering performance across all platforms.",
        qualifications9: "Leader and mentor for others on the developing team in my area of expertise.",
        qualifications10: "Knowledge about browser and performance optimization and their concept.",
        techskills: "Technical Skills:",
        techskills1: "Front-end:",
        techskills2: "HTML - CSS - JavaScript - React.js - Vue.js - Bootstrap - jQuery - WordPress - Three.js - UX/UI - Manipulate DOM.",
        techskills3: "Back-end:",
        techskills4: "Php - Laravel - CodeIgniter - npm - Node.js - Java - Spring - AWS Services - C++ - Python - APIs - ASP.net.",
        techskills5: "Databases:",
        techskills6: "MySQL - MongoDB.",
        techskills7: "Systems, Tools and Platforms:",
        techskills8: "Github - Doker - Jira - Clickup - Kubernetes - Cpanel - Hosting - Domain - SLL - Apache - NGINX - Servers - DevOps - SEO - Mobiles.",
        softskills: "Soft Skills:",
        softskills1: "Strong collaborative mindset, fast learning and problem solving.",
        softskills2: "Seek out opportunities to learn, grow and improve continuously.",
        softskills3: "Dicover and fix programming bugs and unexpected problems.",
        softskills4: "Complete tasks efficiently by higher priority.",
        softskills5: "Work and communicate effectivly as a team member.",
        project1: "Front-end interfaces for a plateform, with a responsive design using HTML, CSS and JavaScript.",
        project2: "A control panel that generates unlimited restaurant electronic menus automatically by only providing the details of the restaurant, has a dashboard to keep track of the users, orders and restaurants, the menu sends the orders to the control panel and the whats app of the restaurant,  has a loggin system with tokens that are stored in the database through the server, developed using HTML, CSS, JavaSctipt, Bootstrap, React, Php, Laravel, MySQL and other frameworks.",
        project3: "Educational plateform, has a login system and a control panel for teachers to organize courses and communicate with their students, with a responsive design in two languages using HTML, CSS, Bootstrap, JavaScript, React.",
        project4: "English level test website tests your english level by answering some questions then you can see your score compared to others, all scores are stored in a Json file, developed using HTML, CSS, Bootstrap, JavaScript.",
        project5: "A simple to do list that stores your tasks in the local storage of your device, so you can see them even if you exit/refreash the page.",
        view: "View-live",
        langen: "English",
        langsp: "Spanish",
        chooselang: "Choose CV language:",
    }, 
    sp: {
        langbtn: "Inglés",
        home: "Inicio",
        about: "Conoce más",
        skills: "Habilidades",
        portfolio: "Portafolio",
        hi: "Hola, soy waseela",
        webdev: "Desarrollador web",
        downloadcv: "Descargar CV",
        summery: "Introducción (resumen):",
        summery1: "Waseela es un Ingeniero de software especializado en Desarrollo web.  Ha trabajado en varios proyectos de diseños y desarrollo de servicios, aplicaciones y páginas web interactivas para una gran multitud de clientes.",
        summery2: "Ha trabajado en varias empresas, acumulando más de tres años de experiencia y conocimientos, habilitándolo como un desarrollador que cuenta con un amplio abanico de herramientas y habilidades.",
        education: "Formación:",
        education1: "Ciencias en  Ingeniería Mecatrónica, Future University, Khartoum, Sudán, 07/2016 - 10/2021, graduado con Matrícula de Honor.",
        courses: "Cursos:",
        courses1: "“Programación en Java” (Resolución de problemas con software), cursado en la Duke university a través de Coursera.",
        courses2: "“Programación en Java orientada a objetos” cursado en la Universidad de California, San Diego, a través de Coursera.",
        courses3: "“Iniciación a Spring Boot” a través de Amigoscode.",
        experience: "Experiencia profesional:",
        experience1: "Más de tres años de experiencia profesional en ingeniería de software: dos años encabezando proyectos como “front-end” y un año como desarrollador “back-end”. Durante estos tres años de experiencia, he consolidado y ampliado habilidades como:",
        experience2: "Creación de APIs REST y diseños web adaptables.",
        experience3: "Determinar los requisitos y las mejores tecnologías para el proyecto en dependiente de su idea.",
        experience4: "Análisis del rendimiento de páginas web, problemáticas de funcionamientos, errores de programación y diagnósticos avanzados.",
        experience5: "Creación de interfaces con capas de acceso a datos para sistemas de SQL y otros sistemas.",
        experience6: "Construcción de modulares y componentes reutilizables.",
        experience7: "Testeo, monitorización y redacción de documentación en cada nivel del desarrollo del software.",
        experience8: "Implementación de metodologías ágiles en proyectos con clientes internacionales.",
        experience9: "Funcionalidad de API de terceros, como pasarelas de pago y verificación OTP por SMS.",
        experience10: "Elementos dinámicos en páginas web e implementación de servicios públicos y privados en la nube.",
        experience11: "Colaboración con diseñadores y otros profesionales, priorizando el mejor resultado posible del proyecto.",
        experience12: "Asesorar a otros ingenieros en tecnologías, patrones y buenas prácticas mientras lidero con el ejemplo en mi área de especialización.",
        experience13: "Mantenimiento y mejora del código base en colaboración con otros miembros de equipo del proyecto por medio de plataformas online como “Github” y “Jira”.",
        experience14: "Organización del trabajo y priorización de las tareas a través de herramientas y plataformas digitales y colaborativas como “Clickup” o “Trello”.",
        experience15: "Trabajo en equipo y coordinación fluida a través de encuentros online por medio de plataformas como “Zoom” o “Google meets”.",
        qualifications: "Cualificación:",
        qualifications1: "La capacidad de hablar tres idiomas: árabe (lengua materna), inglés (fluido) y español (B2).",
        qualifications2: "Sólidos conocimientos en estructuras de datos y algoritmos.",
        qualifications3: "Capacidad para codificar con diferentes lenguajes de programación.",
        qualifications4: "Sólidos conocimientos en el manejo, almacenamiento y recuperación de datos, cookies, sesiones, almacenamiento local y tokens de usuario.",
        qualifications5: "Capacidad para transformar cualquier diseño en una aplicación completamente funcional.",
        qualifications6: "Sólidos conocimientos en hosting, dominios y certificados SSL.",
        qualifications7: "Diseño, planificación e implementación de servicios backend/microservicios y APIs.",
        qualifications8: "Enfoque dirigido a desarrollar software de alta calidad, confiable y con alto rendimiento, adaptándose a distintas plataformas.",
        qualifications9: "Liderazgo y mentoría para otros miembros del equipo de desarrollo en mi área de especialización.",
        qualifications10: "Conocimiento sobre optimización de navegadores y rendimiento, y sus conceptos.",
        techskills: "Habilidades ténicas:",
        techskills1: "Front-end:",
        techskills2: "HTML - CSS - JavaScript - React.js - Vue.js - Bootstrap - jQuery - WordPress - Three.js - UX/UI - Manipular DOM.",
        techskills3: "Back-end:",
        techskills4: "Php - Laravel - CodeIgniter - npm - Node.js - Java - Spring - AWS Servicios - C++ - Python - APIs - ASP.net.",
        techskills5: "Bases de datos:",
        techskills6: "MySQL - MongoDB.",
        techskills7: "Sistemas, herramientas y plataformas:",
        techskills8: "Github - Doker - Jira - Clickup - Kubernetes - Cpanel - Hosting - Domain - SLL - Apache - NGINX - Servidores - DevOps - SEO - móviles.",
        softskills: "Habilidades laborales:",
        softskills1: "Fuerte mentalidad colaborativa, aprendizaje rápido y resolución de problemas.",
        softskills2: "Proactivo en la búsqueda de oportunidades para aprender, crecer y mejorar continuamente.",
        softskills3: "Habilidad para detectar y corregir errores de programación y problemas inesperados.",
        softskills4: "Capacidad para priorizar y completar tareas eficientemente.",
        softskills5: "Trabajo y comunicación efectiva en equipo.",
        project1: "Interfaces front-end para plataforma, con diseño responsivo utilizando HTML, CSS y JavaScript.",
        project2: "Un panel de control que genera menús electrónicos ilimitados de restaurante de forma automática con solo proporcionar los detalles del restaurante, tiene un tablero para llevar un seguimiento de los usuarios, pedidos y restaurantes, el menú envía los pedidos al panel de control y al whats app del restaurante, Tiene un sistema de inicio de sesión con tokens que se almacenan en la base de datos a través del servidor, desarrollado usando HTML, CSS, JavaSctipt, Bootstrap, React, Php, Laravel, MySQL y otros frameworks.",
        project3: "Plataforma educativa, cuenta con un sistema de inicio de sesión y un panel de control para que los docentes organicen cursos y se comuniquen con sus alumnos, con un diseño responsivo en dos idiomas usando HTML, CSS, Bootstrap, JavaScript, React.",
        project4: "La página web del examen de nivel de inglés evalúa su nivel de inglés respondiendo algunas preguntas y luego puede ver su puntaje en comparación con otros, todos los puntajes se almacenan en un archivo Json, desarrollado con HTML, CSS, Bootstrap, JavaScript.",
        project5: "Una lista de tareas sencilla que almacena tus tareas en el almacenamiento local de tu dispositivo, para que puedas verlas incluso si sales o actualizas la página.",
        view: "Ver el proyecto",
        langen: "Inglés",
        langsp: "Español",
        chooselang: "Elige el idioma del CV",
    }
}

export default texts;