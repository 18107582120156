import React, { useState } from 'react'

export const Context = React.createContext({});

export default function ContextProvider({ children }) {

  const [ lang, setLang ] = useState("sp");

  return (
    <Context.Provider value={{ lang, setLang }}>
      {children}
    </Context.Provider>
  )
}