import { useContext, useState } from 'react';
import { Context } from './Context';
import Pic from './images/pic.jpg';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Portfolio from './components/Portfolio';
import Texts from './Texts';

import './App.css';

function App() {

  const [tab, setTab] = useState("home");
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const {lang, setLang} = useContext(Context);
  const texts = Texts[lang];

  function showContent(){
    if (tab === "home") return <Home/>
    if (tab === "about") return <About/>
    if (tab === "skills") return <Skills/>
    if (tab === "portfolio") return <Portfolio/>
  }

  function mobileMenuButton(tab){
    setTab(tab)
    setShowMobileMenu(!showMobileMenu)
  }
  
  function changelang(){
    if (lang === "sp") setLang("en")
    else setLang("sp")
    showMobileMenu && setShowMobileMenu(!showMobileMenu)
  }

  return (
    <div className='container'>
      <div className={`${showMobileMenu ?'header-mobile' : ''} header`}>
        <div>
          <h3 className={tab === 'home' ? 'active' : ''} onClick={()=> setTab("home")}>{texts.home}</h3>
          <h3 className={tab === 'about' ? 'active' : ''} onClick={()=> setTab("about")}>{texts.about}</h3>
          <h3 className={tab === 'skills' ? 'active' : ''} onClick={()=> setTab("skills")}>{texts.skills}</h3>
          <h3 className={tab === 'portfolio' ? 'active' : ''} onClick={()=> setTab("portfolio")}>{texts.portfolio}</h3>
          <h5 className='lang-btn' onClick={() => changelang()}>{texts.langbtn}</h5>
        </div>  
        <h2 onClick={()=> setTab("home")}>Waseela</h2>
        <i className="fa-solid fa-bars" onClick={() => setShowMobileMenu(!showMobileMenu)}></i>
      </div>
      <div className={`${showMobileMenu ? 'mobile-menu-active' : ''} mobile-menu`}>
        <h3 onClick={()=> mobileMenuButton("home")}>{texts.home}</h3>
        <h3 onClick={()=> mobileMenuButton("about")}>{texts.about}</h3>
        <h3 onClick={()=> mobileMenuButton("skills")}>{texts.skills}</h3>
        <h3 onClick={()=> mobileMenuButton("portfolio")}>{texts.portfolio}</h3>
        <h5 className='lang-btn-mobile' onClick={() => changelang()}>{texts.langbtn}</h5>
      </div> 
      <div className='content-container'>
        <div className='content'>
          {showContent()}
        </div>
        <div className='profile'>
          <img src={Pic}  onClick={()=> setTab("home")} alt='img'></img>
        </div>
      </div>
    </div>
  );
}

export default App;
