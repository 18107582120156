import { useContext, useState } from 'react';
import { Context } from '../Context';
import Texts from '../Texts';
import '../App.css';
import cvEN from '../files/cv-en.pdf';
import cvSP from '../files/cv-sp.pdf';


function Home() {

  const {lang} = useContext(Context);
  const texts = Texts[lang];
  const [showCV, setShowCV]= useState(false);

  return (
    <div className='animation'>
      <h1>{texts.hi}</h1>
      <h2>{texts.webdev}</h2>
      <div className='icons'>
        <a href='mailto: d2105944@gmail.com' target='_blank'>
            <i className="fa-solid fa-envelope"></i>
        </a>
        <a href='https://twitter.com/waseela2k' target='_blank'>
            <i className="fa-brands fa-twitter"></i>
        </a>
        <a href='https://github.com/waseelaEngineer' target='_blank'>
            <i className="fa-brands fa-github"></i>
        </a>
        <a href='https://www.linkedin.com/in/mohamed-waseela-2b48912ab/' target='_blank'>
            <i className="fa-brands fa-linkedin"></i>
        </a>
        <a href='https://wa.me/+34673855036?text=Hi' target='_blank'>
            <i className="fa-brands fa-whatsapp"></i>
        </a>
        <a href='https://www.instagram.com/codingwasela/' target='_blank'>
            <i className="fa-brands fa-instagram"></i>
        </a>
        <a href='https://t.me/D21059644' target='_blank'>
            <i className="fa-brands fa-telegram"></i>
        </a>
        <a href='tel: +34673855036' target='_blank'>
            <i className="fa-solid fa-phone"></i>
        </a>
      </div>
      <button className='cv-btn' onClick={()=> setShowCV(!showCV)}>{texts.downloadcv}</button>
      <div className={`download-container ${showCV ? 'show' : ''}`} >
        <div className='download-card animati'>
          <div>
            <p>{texts.chooselang}</p>
            <i className="fa-solid fa-xmark" onClick={()=> setShowCV(!showCV)}></i>
          </div>
            <a href={cvSP} download onClick={()=> setShowCV(!showCV)}>
              <button>{texts.langsp}</button>
            </a>
            <a href={cvEN} download onClick={()=> setShowCV(!showCV)}>
              <button>{texts.langen}</button>
            </a>
        </div>
      </div>
    </div>
  );
}

export default Home;