import { useContext } from 'react';
import { Context } from '../Context';
import Texts from '../Texts';
import '../App.css';

function Skills() {

  const {lang} = useContext(Context);
  const texts = Texts[lang];

  return (
    <div className='animation'>
      <ul>
        <h2>{texts.techskills}</h2>
        <li><strong>{texts.techskills1}</strong>{texts.techskills2}</li>
        <li><strong>{texts.techskills3}</strong>{texts.techskills4}</li>
        <li><strong>{texts.techskills5}</strong>{texts.techskills6}</li>
        <li><strong>{texts.techskills7}</strong>{texts.techskills8}</li>
      </ul>
      <br/><br/>
      <ul>
        <h2>{texts.softskills}</h2>
        <li>{texts.softskills1}</li>
        <li>{texts.softskills2}</li>
        <li>{texts.softskills3}</li>
        <li>{texts.softskills4}</li>
        <li>{texts.softskills5}</li>
      </ul>
    </div>
  );
}

export default Skills;