import { useContext } from 'react';
import { Context } from '../Context';
import Texts from '../Texts';
import '../App.css';

function About() {

  const {lang} = useContext(Context);
  const texts = Texts[lang];

  return (
    <div className='animation'>
      <h2>{texts.summery}</h2>
      <p>{texts.summery1}<p>
      </p>{texts.summery2}</p>
      <br/><br/>

      <h2>{texts.education}</h2>
      <p>{texts.education1}</p>
      <br/><br/>

      <h2>{texts.courses}</h2>
      <ul>
        <li>{texts.courses1}</li>
        <li>{texts.courses2}</li>
        <li>{texts.courses3}</li>
      </ul>
      <br/><br/>

      <h2>{texts.experience}</h2>
      <p>{texts.experience1}</p>
      <ul>
        <li>{texts.experience2}</li>
        <li>{texts.experience3}</li>
        <li>{texts.experience4}</li>
        <li>{texts.experience5}</li>
        <li>{texts.experience6}</li>
        <li>{texts.experience7}</li>
        <li>{texts.experience8}</li>
        <li>{texts.experience9}</li>
        <li>{texts.experience10}</li>
        <li>{texts.experience11}</li>
        <li>{texts.experience12}</li>
        <li>{texts.experience13}</li>
        <li>{texts.experience14}</li>
        <li>{texts.experience15}</li>
      </ul>
      <br/><br/>

      <h2>{texts.qualifications}</h2>
      <ul>
        <li>{texts.qualifications1}</li>
        <li>{texts.qualifications2}</li>
        <li>{texts.qualifications3}</li>
        <li>{texts.qualifications4}</li>
        <li>{texts.qualifications5}</li>
        <li>{texts.qualifications6}</li>
        <li>{texts.qualifications7}</li>
        <li>{texts.qualifications8}</li>
        <li>{texts.qualifications9}</li>
        <li>{texts.qualifications10}</li>
      </ul>
    </div>
  );
}

export default About;